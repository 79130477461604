import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode, FaLink } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj34 = () => {
  return (
    <Layout>
      <SEO title="Weather App" description="Awesome python project" />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-27">
          <div className="py-1-heading">
            <h1>Weather App</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Django-Weather-App"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>
        <div className="git">
          <a
            href="https://arpitsomani.herokuapp.com/"
            target="_blank"
            rel="noopener noreferrer"
            title="View live"
          >
            <FaLink className="git-btn"></FaLink>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              Wanna go somewhere, Checkout the weather outside?
              <br />
              <br />
              This is a awosome django based web-app.
              <br />
              You can check all existing weather of :<br />
              Cities, States, Countries.
              <br />
              But, a valid name is must.
              <br />
              At, backend SQLite is used for queries.
              <br />
              <br />
              The data is fetched from OpenWeatherMap.
              <br />
              You can get your own API key for it, after signup, and you are
              good to go.
              <br />
              <br />
              Finally, deployed on super-cool 'Heroku'.
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Django</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Requests</span>
              </button>

              <button type="button" className="btn-py-1" disabled>
                <span>SQLite3</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Gunicorn</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Heroku</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj34
